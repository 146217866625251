import Box from "./Box";
import DotLineTextItem from "./DotLineTextItem";
import TwoColStickyHeading from "./TwoColStickyHeading";
import TwoColTwoStickyHeadings from "./TwoColTwoStickyHeadings";

function HeadingAndDotLineTextItems({
  mainHeading,
  secondHeading,
  items,
  locale,
}) {
  if (secondHeading) {
    return (
      <TwoColTwoStickyHeadings
        heading1={mainHeading}
        heading2={secondHeading}
        pt={[35, 100, 175, null]}
        locale={locale}
      >
        {items.map((item, index) => {
          const lastOfType = index === items.length - 1;
          return (
            <Box gridArea={`item${index + 1}`} key={item.heading}>
              <DotLineTextItem
                heading={item.heading}
                paragraphs={item.paragraphs}
                animData={item.animData}
                lastOfType={lastOfType}
                animSize={[116, null, 160, null]}
              />
            </Box>
          );
        })}
      </TwoColTwoStickyHeadings>
    );
  } else {
    return (
      <TwoColStickyHeading heading={mainHeading} pt={[35, 100, 175, null]}>
        <Box pt={[0, 0, 10, null]}>
          {items.map((item, index) => {
            const lastOfType = index === items.length - 1;
            return (
              <DotLineTextItem
                key={item.heading}
                heading={item.heading}
                paragraphs={item.paragraphs}
                animData={item.animData}
                lastOfType={lastOfType}
              />
            );
          })}
        </Box>
      </TwoColStickyHeading>
    );
  }
}

export default HeadingAndDotLineTextItems;
