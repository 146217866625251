import Box from "./Box";
import LottieOnEntered from "./LottieOnEntered";

function LottieInCircle({ animData, backgroundColor }) {
  return (
    <Box
      backgroundColor={backgroundColor}
      width="100%"
      height={0}
      pb="100%"
      borderRadius="100%"
      position="relative"
    >
      <Box
        position="absolute"
        height="100%"
        width="100%"
        top={0}
        left={0}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <LottieOnEntered
          animData={animData}
          threshold={0}
          display="flex"
          height="60%"
          width="60%"
          justifyContent="center"
          alignItems="center"
        />
      </Box>
    </Box>
  );
}

export default LottieInCircle;
