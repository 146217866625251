import { useEffect, useState } from "react";

import ArrowButton from "./ArrowButton";
import Box from "./Box";
import BoxWithGutter from "./BoxWithGutter";
import { DOWN } from "../core/constants";
import { useViewportSize } from "../core/hooks";

function Hero({ anchorPointId, transitionDelay, children, hasArrowButton }) {
  const { innerHeight } = useViewportSize();
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
  }, []);

  return (
    <>
      <BoxWithGutter
        height={innerHeight}
        minHeight={[null, null, 800, null]}
        maxHeight={[null, null, 900, null]}
        display="flex"
        justifyContent="center"
        position="relative"
      >
        {children}
        {hasArrowButton && (
          <Box
            zIndex={3}
            transition="opacity 800ms"
            transitionDelay={transitionDelay}
            opacity={animate ? 1 : 0}
          >
            <ArrowButton
              handleClick={() => {
                document
                  .getElementById(anchorPointId)
                  .scrollIntoView({ behavior: "smooth" });
              }}
              direction={DOWN}
              position="absolute"
              bottom={[20, 20, 70, 70]}
              left="calc(50% - 24px)"
            />
          </Box>
        )}
      </BoxWithGutter>
    </>
  );
}

export default Hero;
