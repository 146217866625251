import { useEffect, useRef } from "react";

import Box from "./Box";
import LottiePlayer from "./LottiePlayer";
import { useEnteredViewport } from "../core/hooks";

function LottieOnEntered({ animData, threshold, delay, ...props }) {
  const playerRef = useRef();
  const { ref: containerRef, entered } = useEnteredViewport(threshold);

  useEffect(() => {
    if (entered) {
      if (playerRef.current) {
        if (delay) {
          setTimeout(() => {
            if (playerRef.current) {
              // NB check again as time has elapsed
              playerRef.current.play();
            }
          }, delay);
        } else {
          playerRef.current.play();
        }
      }
    }
  }, [entered]);

  return (
    <Box ref={containerRef} {...props}>
      <LottiePlayer
        ref={playerRef}
        autoplay={false}
        loop={false}
        src={animData}
        rendererSettings={{
          preserveAspectRatio: "xMidYMid meet",
        }}
        pauseWhenNotInView={false}
        border="1px solid yellow"
      />
    </Box>
  );
}

export default LottieOnEntered;
