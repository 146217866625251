import { useEffect, useRef, useState } from "react";

import BodyText from "./BodyText";
import BodyTextLarge from "./BodyTextLarge";
import Box from "./Box";
import { mapValues } from "../utils/utils";
import LottieInCircle from "./LottieInCircle";

function DotLineTextItem({
  heading,
  paragraphs,
  lastOfType,
  animData,
  animSize,
  ...props
}) {
  const barRef = useRef();
  const dotRef = useRef();
  const [dotLit, setDotLit] = useState(false);
  const [barLit, setBarLit] = useState(false);

  const textRef = useRef();

  const loopRef = useRef();
  const rafRef = useRef();

  const circleWidth = animSize ? animSize : 126;

  loopRef.current = () => {
    if (textRef.current) {
      const opacityEndPoint = 190;
      const opacityStartPoint = opacityEndPoint * 2;
      const { top } = textRef.current.getBoundingClientRect();

      if (top <= opacityStartPoint) {
        setDotLit(true);
        setBarLit(true);
      } else {
        setDotLit(false);
        setBarLit(false);
      }

      const opacity = mapValues(
        top,
        opacityStartPoint,
        opacityEndPoint,
        0.2,
        1
      );
      textRef.current.style.opacity = opacity;
    }

    rafRef.current = requestAnimationFrame(loopRef.current);
  };

  useEffect(() => {
    rafRef.current = requestAnimationFrame(loopRef.current);

    return () => {
      cancelAnimationFrame(rafRef.current);
    };
  }, []);

  return (
    <Box display="flex" {...props}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        pr={[38, null, 120, null]}
      >
        <Box
          mt={["10px", null, "13px", null]}
          mb={["10px", null, "13px", null]}
          height="12px"
          width="12px"
          position="relative"
        >
          <Box
            backgroundColor="transparentGrey"
            height="100%"
            width="100%"
            position="absolute"
            borderRadius="100%"
          />
          <Box
            ref={dotRef}
            backgroundColor="lime"
            height="100%"
            width="100%"
            position="absolute"
            borderRadius="100%"
            opacity={dotLit ? 1 : 0}
          />
        </Box>

        {!lastOfType && (
          <Box flex={1} width="2px" position="relative">
            <Box
              backgroundColor="transparentGrey"
              height="100%"
              width="100%"
              position="absolute"
              top={0}
              left={0}
            />
            <Box
              ref={barRef}
              height="100%"
              width="100%"
              position="absolute"
              top={0}
              left={0}
              backgroundColor="cyan"
              transition="transform 700ms cubic-bezier(0.16, 1, 0.3, 1)"
              transformOrigin="top"
              transform={barLit ? "scale3d(1,1,1)" : "scale3d(1,0,1)"}
            />
          </Box>
        )}
      </Box>
      <Box ref={textRef}>
        {animData && (
          <Box width={circleWidth} mr="auto" pb={30}>
            <LottieInCircle backgroundColor="gulfBlue" animData={animData} />
          </Box>
        )}
        <Box pb={[20, null, 30, null]}>
          <BodyTextLarge color="lime">{heading}</BodyTextLarge>
        </Box>
        <Box
          pb={[lastOfType ? 0 : 20, null, lastOfType ? 0 : 58, null]}
          mb={lastOfType ? -30 : 0}
        >
          {paragraphs.map((paragraph, index) => {
            return (
              <BodyText key={index} pb={30}>
                {paragraph}
              </BodyText>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}

export default DotLineTextItem;
