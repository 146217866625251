import {
  background,
  border,
  color,
  flexbox,
  grid,
  layout,
  position,
  space,
} from "styled-system";

import Box from "./Box";
import Image from "./Image";
import MediumHeading from "./MediumHeading";
import NarrowCol from "./NarrowCol";
import styled from "styled-components";
import { useViewportSize } from "../core/hooks";

const CoverImageBox = styled.div`
  ${color}
  ${space}
  ${layout}
  ${flexbox}
  ${grid}
  ${background}
  ${border}
  ${position}
  margin-left: -23px;
  @media (min-width: 960px) {
    margin-left: -70px;
  }
  @media (min-width: 1420px) {
    margin-left: calc((((100vw - 1280px) / 2) * -1));
  }
`;

// For if we bring a max width in, eg 1920px
// max-width: 1920px;
// @media (min-width: 1920px) {
//   margin-left: calc((((100vw - 1280px) / 2) * -1) + ((100vw - 1920px) / 2));
// }

function TwoColStickyHeading({ heading, image, pt, children }) {
  const { innerHeight } = useViewportSize();

  return (
    <NarrowCol display={[null, "flex", null, null]}>
      {image && (
        <Box
          position={["relative", "sticky", null, null]}
          top={0}
          height={0}
          pb={["75%", innerHeight, null, null]}
          opacity={[1, 0.4, null, null]}
          mb={[25, 0, null, null]}
        >
          <CoverImageBox position="absolute" height="100%" width="100vw">
            <Image
              src={image.src}
              alt={image.alt}
              objectFit="cover"
              position="absolute"
              top={0}
              left={0}
              height="100%"
              width="100%"
            />
          </CoverImageBox>
        </Box>
      )}
      <Box
        width={[null, "50%", null, null]}
        pt={pt}
        position={[null, "sticky", null, null]}
        top={0}
        alignSelf="flex-start"
        zIndex={2}
        pb={[0, 90, null, null]}
      >
        <Box maxWidth={[null, 570, null, null]} mr={[null, 47, null, null]}>
          <MediumHeading>{heading}</MediumHeading>
        </Box>
      </Box>
      <Box
        width={[null, "50%", null, null]}
        pb={[50, 90, null, null]}
        pt={pt}
        position="relative"
        zIndex={2}
      >
        {children}
      </Box>
    </NarrowCol>
  );
}

export default TwoColStickyHeading;
