import { useEffect, useRef, useState } from "react";
import { background, border, layout, position, space } from "styled-system";

import PropTypes from "prop-types";
import styled from "styled-components";

const BaseImage = styled.img`
  ${space}
  ${border}
  ${layout}
  ${background}
  ${position}
  ${(props) => {
    if (props.objectFit) {
      return `object-fit: ${props.objectFit};`;
    }
  }}
`;

function Image({ src, alt, ...props }) {
  const [loaded, setLoaded] = useState(false);
  const imageRef = useRef(null);

  useEffect(() => {
    if (imageRef.current && imageRef.current.complete) {
      setLoaded(true);
    }
  }, [imageRef]);

  return (
    <BaseImage
      ref={imageRef}
      onLoad={() => {
        setLoaded(true);
      }}
      alt={alt}
      src={src}
      style={{ transition: "opacity 500ms", opacity: loaded ? 1 : 0 }}
      {...props}
    />
  );
}

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  style: PropTypes.object,
};

export default Image;
