import Box from "./Box";
import { useRef, useEffect, useState } from "react";
import MediumHeading from "./MediumHeading";
import NarrowCol from "./NarrowCol";
import { useViewportSize } from "../core/hooks";

function TwoColTwoStickyHeadings({ heading1, heading2, pt, children, locale }) {
  const { isDesktop } = useViewportSize();
  const heading1Ref = useRef();
  const container1Ref = useRef();
  const container2Ref = useRef();
  const heading2Ref = useRef();
  const loopRef = useRef();
  const rafRef = useRef();
  const [scrollDir, setScrollDir] = useState("");
  useEffect(() => {
    let lastScrollY = window.scrollY;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.scrollY;

      if (Math.abs(scrollY - lastScrollY) < 0) {
        ticking = false;
        return;
      }
      setScrollDir(scrollY > lastScrollY ? "down" : "up");
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollDir]);

  loopRef.current = () => {
    if (heading1Ref.current && heading2Ref.current) {
      const padding = 30;
      const { bottom: container1Bottom } =
        container1Ref.current.getBoundingClientRect();
      const { bottom: heading1Bottom } =
        heading1Ref.current.getBoundingClientRect();
      const isAtMeetingPoint = container1Bottom === heading1Bottom + padding;
      if (isAtMeetingPoint && scrollDir == "down") {
        heading1Ref.current.classList.add("stickyHeading-heading-transitionUp");
      } else if (isAtMeetingPoint && scrollDir == "up") {
        heading2Ref.current.classList.add(
          "stickyHeading-heading-transitionDown"
        );
      }
    }

    if (!isDesktop) {
      if (
        heading1Ref.current.classList.contains(
          "stickyHeading-heading-transitionUp"
        )
      ) {
        heading1Ref.current.classList.remove(
          "stickyHeading-heading-transitionUp"
        );
      }
      if (
        heading2Ref.current.classList.contains(
          "stickyHeading-heading-transitionDown"
        )
      ) {
        heading2Ref.current.classList.remove(
          "stickyHeading-heading-transitionDown"
        );
      }
    }

    rafRef.current = requestAnimationFrame(loopRef.current);
  };

  useEffect(() => {
    rafRef.current = requestAnimationFrame(loopRef.current);

    return () => {
      cancelAnimationFrame(rafRef.current);
    };
  }, []);

  return (
    <NarrowCol
      display={[null, "grid", null, null]}
      className="TwoColTwoStickyHeadings"
      pt={pt}
    >
      <Box ref={container1Ref} gridArea="heading1">
        <Box
          position={[null, "sticky", null, null]}
          top={pt}
          zIndex={2}
          pb={30}
        >
          <Box
            maxWidth={[null, 570, null, null]}
            mr={[null, 47, null, null]}
            ref={heading1Ref}
            pb={[40, 0, null, null]}
          >
            <MediumHeading>{heading1}</MediumHeading>
          </Box>
        </Box>
      </Box>
      <Box ref={container2Ref} gridArea="heading2">
        <Box
          position={[null, "sticky", null, null]}
          display={["none", "block", null, null]}
          top={150}
          zIndex={2}
          pb={[0, 90, null, null]}
        >
          <Box
            maxWidth={[null, 570, null, null]}
            mr={[null, 47, null, null]}
            ref={heading2Ref}
          >
            <MediumHeading>{heading2}</MediumHeading>
          </Box>
        </Box>
      </Box>
      {children}
      <Box
        maxWidth={[null, 570, null, null]}
        ref={heading2Ref}
        display={["block", "none", null, null]}
        pt={40}
      >
        <MediumHeading>{heading2}</MediumHeading>
      </Box>
    </NarrowCol>
  );
}

export default TwoColTwoStickyHeadings;
